import { get, post } from "../request";
/**库存管理模块 */
export default {
  /**
   * @description 分页查询出入库记录
   * @param {Object} params
   * @returns Object
   */
  queryPagesStorageList(params) {
    return get("/storageRecord/selectAll", params);
  },
};
