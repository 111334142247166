import { get, post } from "../request";
/**预约管理模块 */
export default {
  /**
   * @description 分页查询预约记录
   * @param {Object} params
   * @returns Object
   */
  queryPagesReservationList(params) {
    return get("/reservation/selectAll", params);
  },
  /**
   * @description 分页查询所有类目
   * @param {Object} params
   * @returns Object
   */
  queryPagesReservation(params) {
    return get("/reservationItem/selectAll", params);
  },
  /**
   * @description 新增预约项目
   * @param {Object} params
   * @returns Object
   */
  addReservationProject(params) {
    return post("/reservationItem/add", params);
  },
  /**
   * @description 修改预约项目
   * @param {Object} params
   * @returns Object
   */
  editReservationProject(params) {
    return post("/reservationItem/update", params);
  },
  /**
   * @description 删除预约项目
   * @param {number} params
   * @returns Object
   */
  delReservationProject(params) {
    return post("/reservationItem/delete/"+params);
  },
  /**
   * @description 根据id查询预约项目
   * @param {number} params
   * @returns Object
   */
  queryReservation(params) {
    return get("/reservationItem/selectByReservationItemId/"+params);
  },
  /**
   * @description 查询所有类目
   * @returns Object
   */
  queryAllReservation() {
    return get("/reservationItem/selectReservationItemList");
  },
  /**
   * @description 新增预约订单
   * @param {Object} params
   * @returns Object
   */
  addReservation(params) {
    return post("/reservation/addReservation", params);
  },
  /**
   * @description 修改预约订单
   * @param {Object} params
   * @returns Object
   */
  editReservation(params) {
    return post("/reservation/updateReservation", params);
  },
  /**
   * @description 删除预约订单
   * @param {number} params
   * @returns Object
   */
  delReservation(params) {
    return get("/reservation/deleteReservation?id="+params);
  },
  /**
   * @description 预约时间是否可用
   * @param {Object} params
   * @returns Object
   */
  examineTime(params) {
    return get("/reservation/getTimesState", params);
  },
  /**
   * @description 根据id查询预约信息
   * @param {number} params
   * @returns Object
   */
  queryReservationInfo(params) {
    return get("/reservation/selectReservationId/"+params);
  },
  /**
   * @description 根据id查询预约信息
   * @param {number} params
   * @returns Object
   */
  cancelReservationId(params) {
    return get("/reservation/cancelReservationId/"+params);
  },
  /**
   * @description 根据id查询预约信息
   * @param {object} params
   * @returns Object
   */
  createCaWorkOrder(params) {
    return post("/reservation/createCaWorkOrder",params);
  },

  /** 查询所有项目 列表  */
  queryAllProgramList(){
    return get(`/caItem/selectCaItem`)
  },
};
