
import { get, post } from '../request'
/**商品管理模块 便利店 */
export default {
    /**
         * @description 查询所有商品盈亏列表
         * @param {Object} params 
         * @returns Object
         */
    getAllShopStaList(params) {
        return get("/product/productSta", params,true)
    },
    /**
      * @description 查询所有商品列表
      * @param {Object} params 
      * @returns Object
      */
    getAllShopList(params) {
        return get("/product/selectAll", params, true)
    },

    /**
     * @description 查询便利店 某一批 所有商品列表
     * @param {Object} params 
     * @returns Object
     */
    getBatchAllShopList(params) {
        return get("/product/batch/selectAll", params)
    },
    /**
      * @description 商品规格新增或修改
      * @param {Object} params 
      * @returns Object
      */
    postUpdateOrAddShop(params) {
        return post("/product/specification/save", params)
    },
    /**
     * @description 商品规格删除
     * @param {Object} params 
     * @returns Object
     */
    postDelShop(params) {
        return post("/product/specification/delete", params)
    },

    /**
    * @description 添加库存
    * @param {Object} params 
    * @returns Object
    */
    postAddShopStock(params) {
        return post("/product/productAddStock", params)
    },
    /**
    * @description 添加属性更新
    * @param {Object} params 
    * @returns Object
    */
    postEditShopValue(params) {
        return post("/product/update", params)
    },
    /**
      * @description 停用
      * @param {String} params 
      * @returns Object
      */
    getEditShopUsedById(params) {
        return get(`/product/openById/${params}`)
    },
    /**
         * @description 批量入库
         * @param {String} params 
         * @returns Object
         */
    getProductStorage(params) {
        return get(`/product/productStorage?batchId=${params}`)
    },
    /**
    * @description 单个商品
    * @param {String} params 
    * @returns Object
    */
    getProductByPID(params) {
        return get(`/product/selectByProductId/${params}`)
    },
    /**
        * @description 商品删除
        * @param {String} params 
        * @returns Object
        */
    postDelShopByPID(params) {
        return post(`/product/delete/${params}`)
    },
    /**
     * @description 查询订单商品信息
     * @param {String} params 
     * @returns Object
     */
    getOrderShopInfoByPID(params) {
        return get(`/control/selectById?id=${params}`)
    },
    /**
    * @description 查询所有订单商品信息
    * @param {Object} params 
    * @returns Object
    */
    getAllOrderInfo(params) {
        return get(`/control/selectAll`, params)
    },

    /**
     * @description 单商品退款
     * @param {Object} params 
     * @returns Object
     */
    postRefundProduct(params) {
        return post(`/control/refundControl`, params)
    },
    /**
    * @description 单商品退款 轮询
    * @param {Object} params 
    * @returns Object
    */
    postRefundProductLunxun(params) {
        return post(`/control/refundControlQuery`, params)
    },

    /**
     * @description 添加商品
     * @param {Object} params 
     * @returns Object
     */
    postAddProduct(params) {
        return post(`/product/add`, params)
    },

    /**
    * @description 添加促销商品
    * @param {Object} params 
    * @returns Object
    */
    postAddProductPromotion(params) {
        return post(`/productPromotion/add`, params)
    },
    /**
     * @description 查询所有入库商品信息
     * @param {Object} params 
     * @returns Object
     */
    getProductStorageAll(params) {
        return get(`/productStorage/selectAll`, params, true)
    },
    /**
     * @description 商品采购批次列表
    
        * @returns Object
        */
    getProductStorageBatchAll() {
        return get(`/product/batch/selectAll`, {}, true)
    },
    /**
    * @description 搜索入库商品信息
    * @param {Object} params 
    * @returns Object
    */
    getSearchProductStorage(params) {
        return get(`/productStorage/searchProductStorage`, params)
    },

    /**
     * @description 商品促销列表
     * @param {Object} params 
     * @returns Object
     */
    getProductPromotion(params) {
        return get(`/productPromotion/selectAll`, params, true)
    },
    /**
     * @description 商品促销单查询
     * @param {String} params 
     * @returns Object
     */
    getSearchProductPromotion(params) {
        return get(`/productPromotion/select?id=${params}`)
    },
    /**
     * @description 商品促销停用
     * @param {String} params 
     * @returns Object
     */
    getProductPromotionopenById(params) {
        return get(`/productPromotion/openById/${params}`)
    },

    /**
     * @description 商品促销删除
     * @param {String} params 
     * @returns Object
     */
    getProductPromotionDelById(params) {
        return get(`/productPromotion/deleteById/${params}`)
    },
    /**
         * @description 商品盘点单列表
         * @param {Object} params 
         * @returns Object
         */
    postProductInventorySheet(params) {
        return post(`/productInventoryCheck/selectAll`, params, true)
    },

    /**
    * @description 商品盘点单列表
    * @param {Object} params 
    * @returns Object
    */
    postInventorySheetRowDetailList(params) {
        return post(`/productInventoryCheck/selectInventoryCheckDetails`, params)
    },
     /**
      * @description 商品出入库记录列表
      * @param {Object} params 
      * @returns Object
      */
     postProductStorageRecord(params) {
        return post(`/productStorage/getYnProductStorageRecord`, params, true)
    },
}