
import { get, post } from '../request'
/**发票管理模块 */
export default {
    /**
    * @description 查询所有发票列表
    * @param {String|Number} params 
    * @returns Object
    */
    getAllInvoiceList(params) {
        return post("/invoiceOrders/getInvoiceOrdersList", params, true)
    },

    /**
      * @description 查询发票详情
      * @param {String|Number} params 
      * @returns Object
      */
    invoiceDetail(params) {
        return get(`/invoiceOrders/getInvoiceOrdersDetails/${params}`, {}, true)
    },
    /**
          * @description 发票作废
          * @param {String|Number} params 
          * @returns Object
          */
    invoiceCancel(params) {
        return get(`/invoiceOrders/invoiceCancel/${params}`)
    },

}