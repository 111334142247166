import { get, post } from '../request'

export default {
    /**login */
    /**
    * @description 登录
    * @param {Object} params 
    * @returns Object
    */
    postLogin(params) {
        return post("/sys/user/login", params)
    },
    /**
   * @description 退出登录
   * @param {Object} params 
   */
    getLoginOut() {
        return get("/sys/user/logout")
    },

}