import { createStore } from "vuex";
import router from "@/router";

export default createStore({
  state: {
    hasRoutes: false, // 路由状态
    reFresh: false,// 是否刷新页面
    editableTabsValue: localStorage.getItem("editableTabsValue") || "index", // 当前 选择的tab
    tenantId: sessionStorage.getItem("tenantId") || null,//租户id
    editabTabs: JSON.parse(localStorage.getItem("tabList")) || [
      {
        title: "首页",
        name: "/index",
        component: "Index",
      },
    ],// 顶部选项卡
    baseU: localStorage.getItem("baseUrl"),
    baseUrlIndex: 3, // 指定使用哪一个IP
    authButton: "", //当前页按钮权限
    themeConfig: JSON.parse(localStorage.getItem("themeConfig")) || {
      themes: "light", // 主题样式 默认亮色
      btnColor:''
    }, // 主题 配置
  },
  getters: {
    GET_TOKEN: (state) => {
      return sessionStorage.getItem("token");
    },
    GET_MENULIST: (state) => {
      return JSON.parse(sessionStorage.getItem("menuList"));
    },
    GET_USERINFO: (state) => {
      return JSON.parse(sessionStorage.getItem("userInfo"));
    },
    GET_BASEURL: (state) => {
      return localStorage.getItem("baseUrl");
    },
    GET_STATIONNAME: (state) => {
      return localStorage.getItem("stationName");
    },
    GET_QRSTATE: (state) => {
      return sessionStorage.getItem("qrState");
    },
    GET_TENANTID: (state) => {
      return sessionStorage.getItem("tenantId");
    },
    GET_TENANTLIST: (state) => {
      const d = sessionStorage.getItem("tenantList");
      if (d && d !== null && d !== "undefined") {
        return JSON.parse(sessionStorage.getItem("tenantList"));
      }
    },
    GET_ENABLE: (state) => {
      // 转换成布尔类型
      return sessionStorage.getItem("enable") === "false"
        ? false
        : sessionStorage.getItem("enable") === "true"
        ? true
        : sessionStorage.getItem("enable");
    },
    /*** 检测是否包含 某个权限字段 */
    GET_AUTHSTRING: (state) => (authType) => {
      // console.log("某个权限字段", state.authButton, state.authButton.children);
      if (state.authButton.children.length) {
        auth: for (const at of state.authButton.children) {
          // console.log(at.authButton?.includes(authType));
          if (at.authButton?.includes(authType)) {
            return true;
          }
        }
      }
      return false;
    },
    /** 获取 页面权限按钮列表  */
    GET_PAGEBUTTON: (state) => (authType) => {
      const btnlist = JSON.parse(sessionStorage.getItem("menuList")) || [];
      let nowtab = "";
      if (!btnlist.length) return;
      btnfor: for (const chirend of btnlist) {
        if (chirend.children) {
          const foundItem = chirend.children?.find(
            (item) => item.path === authType
          );
          if (foundItem) {
            nowtab = foundItem;
            state.authButton = nowtab;
            break btnfor; // 匹配到 跳出循环
          }
        }
      }
      console.log(nowtab.children, authType);
    },
    // 获取本地主题样式  没有就是默认值 亮色
    GET_THEMES: (state) => {
      return sessionStorage.getItem("themes") || "light";
    },
  },
  mutations: {
    //是否多站
    SET_ENABLE: (state, enable) => {
      sessionStorage.setItem("enable", enable);
    },

    //是否刷新
    SET_REFRESH: (state, refresh) => {
      state.reFresh = refresh;
    },
    //请求ip
    SET_BASEURL: (state, baseUrl) => {
      localStorage.setItem("baseUrl", baseUrl);
    },
    //油站名称
    SET_STATIONNAME: (state, stationName) => {
      localStorage.setItem("stationName", stationName);
    },
    //token
    SET_TOKEN: (state, token) => {
      sessionStorage.setItem("token", token);
    },
    //菜单列表
    SET_MENULIST: (state, menuList) => {
      sessionStorage.setItem("menuList", JSON.stringify(menuList));
    },
    //用户信息
    SET_USERINFO: (state, userInfo) => {
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    //路由状态
    SET_ROUTES_STATE: (state, hasRoutes) => {
      state.hasRoutes = hasRoutes;
    },
    //二维码状态
    SET_QRcodeState: (state, qrState) => {
      sessionStorage.setItem("qrState", qrState);
    },
    //添加tab选项
    ADD_TABS: (state, tab) => {
      if (state.editabTabs.findIndex((e) => e.name === tab.path) === -1) {
        state.editabTabs.push({
          title: tab.name,
          name: tab.path,
          component: tab?.componentName,
          meta: { keepAlive: true },
        });
      }

      console.log({ tas: state.editabTabs }, tab);
      localStorage.setItem("tabList", JSON.stringify(state.editabTabs));
      state.editableTabsValue = tab.path;
      localStorage.setItem("editableTabsValue", tab.path);
    },
    //重置tab
    RESET_TABS: (state) => {
      state.editableTabsValue =
        localStorage.getItem("editableTabsValue") || "/index";
      state.editabTabs = JSON.parse(localStorage.getItem("tabList")) || [
        {
          title: "首页",
          name: "/index",
          component: "Index",
        },
      ];
    },
    // 租户id设置
    SET_TENANTID: (state, tenantId) => {
      state.tenantId = tenantId;
      sessionStorage.setItem("tenantId", tenantId);
    },
    // 租户列表
    SET_TENANTLIST: (state, tenantList) => {
      if (tenantList) {
        sessionStorage.setItem("tenantList", JSON.stringify(tenantList));
      }
    },
  },
  actions: {
    logout() {
      window.sessionStorage.clear();
      router.replace("/login");
    },
  },
  modules: {},
});
