import router from "@/router/index";
import store from "@/store";

router.beforeEach(async (to, from, next) => {

    const whiteList = ['/login']
    let token = store.getters.GET_TOKEN;
    let hasRoutes = store.state.hasRoutes;
    let menuList = store.getters.GET_MENULIST;

    if (token) {
        if (!hasRoutes) {
            bindRoute(menuList);
            store.commit("SET_ROUTES_STATE", true);
            // next({ ...to, replace: true });
            next({ path: '/index' });
        } else {
            next();
        }

        //router.push(from.path)
    } else {
        if (whiteList.includes(to.path)) {
            next();
        } else {
            next('/login');
        }
    }
})
//添加动态路由
const bindRoute = (menuList) => {
    let newRoutes = router.options.routes;
    menuList.forEach(menu => {
        if (menu.children) {
            menu.children.forEach(m => {
                // 菜单转成路由
                let route = menuToRoute(m, menu.name);
                if (route) {
                    newRoutes[0].children.push(route); // 添加到路由管理
                }
            })
        }
    })
    // 重新添加到路由
    newRoutes.forEach(route => {
        router.addRoute(route)
    })

}
// 菜单转成路由
const menuToRoute = (menu, parentName) => {
    let route = {
        name: menu.name,
        path: menu.path,
        meta: {
            parentName: parentName
        },
        children: [],
    }
    if (!menu.component) {
        route.component = ''
    } else {
        route.component = () => import('@/views/' + menu.component + '.vue')
    }
    return route
}