import { get, post } from '../request'
/**车项目管理模块 */
export default {
    /**
     * @description 查询所有车项目列表
     * @param {Object} params 
     * @returns Object
     */
    getAllProject(params) {
        return get("/caItem/selectAll",params)
    },
    /**
     * @description 添加车项目
     * @param {Object} params 
     * @returns Object
     */
     addProject(params) {
        return post("/caItem/add",params)
    },
    /**
     * @description 修改车项目
     * @param {Object} params 
     * @returns Object
     */
     editProject(params) {
        return post("/caItem/update",params)
    },
    /** 
     * @description 查询单个车项目
     * @param {Number} params 
     * @returns Object
     */
     getOneProject(params) {
        return get("/caItem/selectByCaItemId/"+params)
    },
    /**
     * @description 删除车项目
     * @param {Number} params 
     * @returns Object
     */
     delProject(params) {
        return post("/caItem/delete/"+params)
    },
    /**
     * @description 根据类型与名字模糊查询车项目
     * @param {Object} params 
     * @returns Object
     */
    getProjectAboutName(params){
        return get('/caItem/selectCaItemList',params)
    },
    /**
     * @description 添加菜单
     * @param {Object} params 
     * @returns Object
     */
    addCarMemu(params){
        return post('/caMemu/add',params)
    },
    /**
     * @description 修改菜单
     * @param {Object} params 
     * @returns Object
     */
    editCarMemu(params){
        return post('/caMemu/update',params)
    },
    /**
     * @description 删除菜单
     * @param {number} params 
     * @returns Object
     */
    delCarMemu(params){
        return get('/caMemu/del?id='+params)
    },
    /**
     * @description 分页查询所有菜单
     * @param {Object} params 
     * @returns Object
     */
    pageCarMemu(params){
        return get('/caMemu/page',params)
    },
    /**
     * @description 查询全部菜单
     * @returns Object
     */
    listCarMemu(){
        return get('/caMemu/list')
    },
    /**
     * @description 根据ID查询菜单
     * @param {number} params 
     * @returns Object
     */
    selCarMemu(params){
        return get('/caMemu/sel?id='+params)
    },
    /**
     * @description 获取全部车项目
     * @returns Object
     */
    getAllCarItem(){
        return get('/caItem/selectCaItem')
    },
    /**
     * @description 根据菜单查询车项目
     * @param {object} params 
     * @returns Object
     */
    getCarItemByMenu(params){
        return get('/caItem/selectByCaMenuId',params)
    },
    /**
     * @description 车项目出入库
     * @param {object} params 
     * @returns Object
     */
    editStorage(params){
        return post('/caItem/caItemSeliveryStorage',params)
    }
}