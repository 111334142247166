import { h } from "vue";
import { ElNotification } from "element-plus";

/**  判断 更新版本是否大于应用版本 是1 否 -1 相同 0
 * param1 最新的版本
 * param2 当前app版本
 */
export const compareVersion = (v1, v2) => {
  const arr1 = v1.split(".").map(Number);
  const arr2 = v2.split(".").map(Number);
  const maxLength = Math.max(arr1.length, arr2.length);
  for (let i = 0; i < maxLength; i++) {
    const num1 = arr1[i] || 0;
    const num2 = arr2[i] || 0;
    if (num1 > num2) {
      return 1;
    } else if (num1 < num2) {
      return -1;
    }
  }
  return 0;
};
/*** 更新检测 并提示 */
export const checkUpdateMsg = (newVersion, oldVersion) => {
  const packageJson = require("/package.json");
  const v1 = newVersion || packageJson.version;
  const v2 = oldVersion || localStorage.getItem("appVersion");
  // 如果 本地有缓存版本号 就进行比较，否则只缓存当前版本
  if (v2) {
    if (compareVersion(v1, v2) === 1) {
      console.log("当前app版本", v2, "开始更新",v1);
      ElNotification({
        title: "提示",
        message: h("i", { style: "color: teal" }, "页面有更新，即将进行刷新"),
      });
      setTimeout(() => {
        location.reload();
        localStorage.setItem("appVersion", v1);
      }, 2000);
    } else if (compareVersion(v1, v2) === -1) {
      localStorage.setItem("appVersion", v1);
    } else {
      console.log("当前app版本", packageJson.version, "无需更新");
    }
  } else {
    localStorage.setItem("appVersion", v1);
  }
};
