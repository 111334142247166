/** 请求响应状态码  SUCCESS: 成功,
    FAIL: 成功但反错,
    REQUESTFAIL: 错误,
    NOTFUND: 找不到,
    LUNXUN: 全退款轮询,
    NOAUTH: 无权限 
    REFUND:部分退款
    STATUS:成功响应状态
    */
    const globalCode = {
        SUCCESS: 20000,
        FAIL: 20001,
        REQUESTFAIL: 500,
        NOTFUND: 404,
        LUNXUN: 2002,
        NOAUTH: 401,
        REFUND: 3002,
        STATUS:200
    }
    
    
    export default globalCode;